export default {
    text1:"Home",
    text2:"ICO subscription",
    text3:"Verify",
    text4:"Cloud dig",
    text5:" Quotes ",
    text6:" Coins ",
    text7:" Trading ",
    text8:" Mine ",
    text9:"Rapid top-up",
    text10:"Recharge",
    text11:"Name",
    text12:"Latest price",
    text13:"Rise and fall",
    text14:"Volume ",
    text15:"Buy",
    text16:"Sell",
    text17:"Limit price",
    text18:"market price",
    text19:"price",
    text20:"number",
    text21:"Handling fee",
    text22:"Please enter the number",
    text23:"available",
    text24:"transaction amount",
    text25:"BuyBTC",
    text26:"SellBTC",
    text27:"New Deal>",
    text28:"Current delegate",
    text29:"More",
    text30:"No record yet",
    text31:"Please enter the price",
    text32:"cancel",
    text33:"Trading at the current optimal price",
    text34:"Delivery Contract",
    text35:"high",
    text36:"low",
    text37:"24H",
    text38:"second",
    text39:"profit rate",
    text40:"Transaction mode",
    text41:"Opening quantity",
    text42:"Second contract account",
    text43:"Buy Up",
    text44:"Buy Down",
    text45:"In transaction",
    text46:"Bargain position",
    text47:"Please log in",
    text48:"Welcome to QCM",
    text49:"Cerdit",
    text50:"Logout",
    text51:"My assets",
    text52:"All assets",
    text53:"Available",
    text54:"Accounts",
    text55:"Universal",
    text56:"Deposit",
    text57:"Withdraw coins",
    text58:"Account transfer",
    text59:"exchange",
    text60:"My share link",
    text61:"Security Center",
    text62:"Bind the withdrawal address",
    text63:"white paper",
    text64:"Submit Ticket",
    text65:"Night Mode",
    text66:" Deposit coins ",
    text67:"Please select the following recharge channel, and the wallet address will be update from time to time.",
    text68:"Official cooperative recharge channel",
    text69:" register ",
    text70:"email registration",
    text71:"cannot be modified after registration",
    text72:"Please enter your email",
    text73:"Please enter the password",
    text74:"Please enter confirm password",
    text75:"Please enter the verification code",
    text76:"Please enter your phone number",
    text77:"invite code",
    text78:"Remember password",
    text79:"I agree",
    text80:"User Agreement and Privacy Policy",
    text81:"send",
    text82:"Forgot your password?",
    text83:"login",
    text84:"Not yet QCM ?",
    text85:" Forgot your password? ",
    text86:"Next",
    text87:"Mailbox retrieved",
    text88:"Reset password",
    text89:"",
    text90:"",
    text91:"",
    text92:"",
    text93:"",
    text94:"",
    text95:"",
    text96:"",
    text97:"",
    text98:"",
    text99:"",
    text100:"",
    text101:"",
    text102:"",
    text103:"",
    text104:"",
    text105:"",
    text106:"",
    text107:"",
    text108:"",
    text109:"",
    text110:"",
    text111:"",
    text112:"",
    text113:"",
    text114:"",
    text115:"",
    text116:"",
    text117:"",

}