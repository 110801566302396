var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "empty-box"
  }, [_c('img', {
    staticStyle: {
      "width": "50px",
      "height": "50px"
    },
    attrs: {
      "src": require("@/assets/images/trade/nodata.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "empty-text"
  }, [_vm._v(_vm._s(_vm.$t('text30')))])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };